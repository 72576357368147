<template>
  <div class="priceOptions">
    <p class="text text_white">$ {{positionPrice}}</p>
  </div>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';
  import {changePriceForm} from '../../../helpers/aroundPrice';

  export default {
    name: "PriceOptions",

    computed: {
      ...mapState('options', ['priceArray', 'resultP']),
    },
  }
</script>

<style lang="scss" scoped>
  .priceOptions {
    position: absolute;
    bottom: 3rem;
    left: auto;
    right: 2rem;
    display: inline-block;
    width: auto;
    .text {
      margin: 0;
    }
  }
</style>
